<template>
  <div>
    <div class="carousel-form">
      <el-input
        v-model="input"
        placeholder="按照标题/内容查找"
        @input="display"
        style="width: 300px;margin-right:20px"
      ></el-input>
      <!-- <el-button type="primary" @click="addCarousel">新增</el-button> -->
    </div>
    <div class="carousel-table">
      <el-table :data="tableData" ref="tableData" border style="width: 100%;">
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="img" label="主题图片" width="200">
          <template slot-scope="scope">
            <img :src="api+scope.row.img" alt style="width:150px" />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="主题名称" width="200"></el-table-column>
        <el-table-column prop="content" label="主题内容" width="200"></el-table-column>
        <el-table-column prop="createTime" label="创作时间" width="200"></el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button @click="modify(scope.row)" class="button-icon" type="text" size="small">编辑</el-button>
            <!-- <el-button type="text" size="small" class="button-icon" @click="Delete( scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="position:relative;width:100%;height:30px;padding-top:20px">
      <!-- <el-pagination
        style="position:absolute;right:0"
        @size-change="handleSizeChange"
        @current-change="currentPage"
        :current-page="currentPg"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination> -->
    </div>
  </div>
</template>

<script>
import { DELETE, QUERY,QUERYED } from "@/services/dao.js";
import { Alert } from 'element-ui';
import { YearMMdd } from "@/js/common/index";
export default {
  data() {
    return {
      api:this.api.LoginURL.concat(),
      name: "",
      input: "",
      currentPg: 0,
      pageSize: 20,
      total: 800,
      tableData: [],
      table: []
    };
  },
  created() {
    this.display();
  },
  methods: {
    //   init(){
    //      this.searchInfo(this.pageSize,0);
    // },
    //c查询
    // flite(input) {
    //   let that = this;
    //   //  filter  数组API    includes  字符串API
    //   //  和数组中的 includes类似
    //   that.tableData = that.table.filter(Val => {
    //     if (
    //       Val.title.includes(that.input) ||
    //       Val.content.includes(that.input)
    //     ) {
    //       that.tableData.push(Val);
    //       return that.tableData;
    //       //alert(1)
    //     }
    //   });
    // },

    ///初始化加载数据
    async display() {
       this.tableData=[]
      //页面加载
      let data = await QUERYED(
        "POST",
        "",
       '  TtTheme( where: {title: {_like: "%' +
            this.input +
            '%"}}) {title img id content createTime}   Theme_aggregate {  aggregate {   count   }  }'
      );
      let that = this;
      // that.tableData.splice(0, this.tableData.length);
      for (let i = 0; i < data.data.TtTheme.length; i++) {
        that.tableData.push({
          id: data.data.TtTheme[i].id,
          img: data.data.TtTheme[i].img,
          title: data.data.TtTheme[i].title,
          content: data.data.TtTheme[i].content,
          createTime: YearMMdd(data.data.TtTheme[i].createTime)
        });
        // return that.tableData;
        this.total = data.data.Theme_aggregate.aggregate.count;
        //  this.$message.success(that.tableData);
      }
    },
    //前一页
    prevPage(val) {
      this.pageNum = val - 1;
      this.display(this.pageSize, this.pageSize * this.pageNum);
    },
    //下一页
    nextPage(val) {
      this.pageNum = val - 1;
      this.display(this.pageSize, this.pageSize * this.pageNum);
    },
    //当前页
    currentPage(val) {
      console.log(val);
      this.pageNum = val - 1;
      this.display(this.pageSize, this.pageSize * this.pageNum);
    },
    //切换每页条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.display(this.pageSize, 0);
    },
    handleClick(row) {
      console.log(row);
    },
    async addCarousel() {
      this.$router.push("/addthemesd");
    },

    async Delete(row) {
      let data = await DELETE(
        "post",
        "",
        "delete_TtTheme(where: {id: {_eq: " + row.id + "}}) {  affected_rows }"
      );
      console.log(data);
      if (data.data.delete_TtTheme.affected_rows > 0) {
        this.display();
        //alert("删除成功!");
        this.$message({
          message: "删除成功!",
          type: "success"
        });
        this.display(this.pageSize, 0);
      }
    },
    async modify(row) {
      this.$router.push({
        path: "/modifytheme",
        query: {
          id: row.id
        }
      });
      console.log(row);
    }
    //         //每页页数
    //        handleSizeChange(val){
    //            this.pageSize=val;
    //           //  this.searchInfo(this.pageSize,0);
    //        },
    //     //  当前页
    // handlecurrentPage(val){
    //  console.log(val);
    //  this.currpage=val;
    //             // this.pageNum=val-1;
    //             // this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
    //     },
  }
};
</script>

<style>
.carousel-table {
  margin-top: 20px;
  text-align: center;
}
.button-icon {
  /* width: 100px;
  height: 30px; */
  text-align: center;
  margin-bottom: 5px;
}
</style>